.cube{
    color: black;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    width: 60px ;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px ;
    background-image: initial;
    background-position: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    backface-visibility: visible;
    transform: scale(1, 1);
    z-index: 1;
    background-clip: padding-box !important;
 }
 .Cubesec{
    position: relative;
    transform: translateZ(0px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
 }
 .Cubename{
    margin: 30px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
 }
 .Cubenames{
    margin: 30px 0px 0px;
    max-width: 355px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
 }


 .Game-keyboard-button {
    align-items: center;
    background-color: #dce1ed;
    color: #5a6376!important;
    border: 2px solid transparent;
    border-radius: 4px;
    color: inherit;
    cursor: pointer;
    user-select: none;
    display: flex;
    flex: 1 1;
    font-size: 17px;
    font-weight: 700;
    justify-content: center;
    margin: 3px;
    min-height: 40px;
    padding: 3px;
    text-decoration: inherit;
    text-transform: capitalize;
    transition: .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0.3);
    -webkit-touch-callout: none;
}


@media (max-width: 767px){
    .Game-keyboard-button {
        margin: 2px;
    }}
@media (max-width: 991px){
.Game-keyboard-button {
    font-size: 16px;
}}
@media (min-width: 427px){
    .cube {
        width: 68px;
        height: 68px;
    }}

    @media (max-width: 259px){
        .cube {
            width: 40px;
            height: 40px;
        }}