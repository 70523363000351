.rankbox {
    display: flex;
    width: 26%;
    position: relative;
    height: 183px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 19px;
    border: 1px solid rgb(0, 123, 255);
    box-shadow: 2px 13px 15px -13px rgba(0, 0, 0, .75);

}

.Mainrankbox {
    display: flex;
    width: 80%;
    justify-content: space-around;
}

.Rankname {
    color: black
}

.box {
    
    /* left: 89px; */
    width: 100%;
    height: 200px;

    display: flex;
    justify-content: space-around;
}

.Rankimage {
    height: 52px !important;
    width: 52px !important;
    color: gray;
}