.Savescore{padding: 12px 30px;
    background: rgb(255, 209, 84);
    display: inline-block;
    border: none;
    border-radius: 3px;
    transition: 0.1s linear 0s;
    font-weight: bold;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    margin: 5px 5px 0px;
    color: rgb(51, 51, 51) !important;
    text-decoration: none !important;
    
   
}

.Tryagain{
    padding: 12px 30px;
    display: inline-block;
    border: none;
    border-radius: 3px;
    transition:  0.1s linear 0s;
    font-weight: bold;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    margin: 5px 5px 0px;
    color: rgb(51, 51, 51) !important;
    text-decoration: none !important;
  
    
}

.Time{
    font-size: 6vh;
}
@media (min-width: 500px){
    .Time
        .css-saet2v h1 {
            font-size: 80px
        }
}

@media (max-width:500px) {
    .Icon{
        height:50px ;
        width: 50px;
    }

}
.Icon{
width: 100px;
height: 100px;
}

 .Tryagain:hover {
    background: white;
}