.Wrodwraper{
display: flex;
justify-content: center;
align-items: center;

}

.color{
    background: rgb(43, 135, 209);
     margin-top: 20px;
}