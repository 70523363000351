.Cubename{
    border:15px solid transparent;
    margin: 10px 0px 0px 0px;
    background: rgb(43, 135, 209);

   } 
   
  .Cubesec{
    position: relative;
    transform: translateZ(0px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
 }