.Cube{
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 113px; */
    background-image: initial;
    background-position: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-color: rgb(0, 0, 102);
    opacity: 0.15;
    border-radius: 10px;
    backface-visibility: visible;
    
    z-index: 1;

    background-clip: padding-box !important;
 }




 .Animate{
   animation: example 1s linear 1s 1 alternate;

 }


 @keyframes example {
 
  50%  { background-color: white;
   opacity: 1;
   transform: rotateX(180deg);}
  100% {
   background-color: rgb(0, 0, 102);
   opacity: 0.15;
   transform: rotateX(0deg)
  }
}
.reverseAnimate{
  animation: reverseanimate 0.2s linear 0.1s 1 alternate;
  background-color: white;
 
    opacity: 1;
}

@keyframes reverseanimate{
 
  100% {
    background-color: white;
    transform: rotateX(180deg);
    opacity: 1;
  }
}


@media (max-width:800px) {
  /* .Cube{
    height: 100px !important;
    width: 100px !important;
  } */
  .Mainboxofcube{
    width: 310px !important;
    height: 310px !important;
    
  }
  
}

.Mainboxofcube{
  width: 345px;
  height: 345px;
  display: flex;
  flex-wrap: wrap;
  
}

.red-border{
border: 2px solid red;
}
.yellow-border{
  border: 2px solid yellow;
}
.black-border{
  border: 2px solid black;
}

.Quezmianbox{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

}