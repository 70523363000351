
.display{
    height: 500px;
    width: 100%;
    background: rgb(43, 135, 209);
    position: relative;
    color: white;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center
    ;
}
.Mainbox{
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px auto;
    height: calc(100vh - 150px);
    position: relative;
    padding: 10px;
}
.Secondbox{
    animation-name: slideFadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
}
@keyframes slideFadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
.Icon{
    width: 80px !important;
    height: 80px !important;
}

.textbox{
    margin: 30px 0px 0px;

}

.textfirst{
    font-size: 6vh;

}

.Scorenaem{
    font-size: 6vh;

}

@media (min-width: 500px){
    .Scorenaem {
        font-size: 80px;
    }
}
.Discription{
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.5;
}
.Flex{
    display: flex;
    justify-content: center;
    margin: auto;
  
}
@media (max-width:342px) {
    .Flex{
        display:flex;
        flex-direction: column;
        margin: auto;
     
       
    }
}



.Abouttest{
    color: black;
    display: flex;
    justify-content:start;
    flex-direction: column;
    align-items:flex-start;
    margin: 20px;
    margin-bottom: 30px;
}
.Abouttest .p .h1{
    text-align: start;

}
