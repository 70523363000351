

   
    .thirdwraper{ display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px auto;
    height: calc(100vh - 150px);
    position: relative;
    padding: 10px;
    background: rgb(43, 135, 209)
}

    .Logo{
      
    
    }
    .Logo svg{
        width: 128px;
        height: 128px;
        fill: currentcolor;
        animation: 1s ease-in-out 0s infinite alternate none running pulsefaint;
    }

    @keyframes  pulsefaint{
        0% {opacity: 0.7}
        100%{opacity: 1}
        
    }
    .Text{
        margin: 30px 0px 0px;
    }
     .Text h1{
        font-size: 5vh;
    }
    .Text h2{
        font-size: 3vh;
    }
    .Button{
        margin: 30px 0px 0px;
    }
    .Link{
        padding: 12px 30px;
    background: rgb(255, 209, 84);
    display: inline-block;
    border: none;
    border-radius: 3px;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    transition-delay: 0s;
    transition-property:background;
    font-weight: bold;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    color: rgb(51, 51, 51) !important;
    text-decoration: none !important;
    }