
.css-12ibl39 {
    background: rgb(43, 135, 209);
    position: relative;
    cursor: pointer;
    color: white;
    user-select: none;
    overflow: hidden;
    text-align: center;
}

.css-42wpoy {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px auto;
    height: calc(100vh - 150px);
    position: relative;
    padding: 10px;
}
.css-1qvtbrk h1 {
    font-size: 6vh;
}
.css-1qvtbrk h2 {
    font-size: 4vh;
}
.anim-slide-fade-in {
    animation-name: slideFadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
}
@keyframes slideFadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
.css-1qvtbrk + .css-1qvtbrk {
    margin: 30px 0px 0px;
}
.css-de05nr {
    padding: 12px 30px;
    background: rgb(255, 209, 84);
    display: inline-block;
    border: none;
    border-radius: 3px;
    transition:  0.1s linear 0s;
    font-weight: bold;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    color: rgb(51, 51, 51) !important;
    text-decoration: none !important;
}

.Gamenam{
    color: white;
    display: flex;
    height: 50px;
    box-shadow: 2px 13px 15px -13px rgba(0, 0, 0, 0.75);
    background: rgb(43, 135, 209);
    width: 87%;
    margin: auto;
    align-items: center;
    margin-bottom: 12px;
    justify-content: center;
    margin-top: 62px;
}