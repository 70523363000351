* {
    margin: 0;
    padding: 0;
    font-weight: 400;
    outline: 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 18px;
}



.Truans {
    margin-top: 20px;
    padding: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    text-align: center;
    background-color: rgb(84, 255, 84);
}

.Maionpage {
    background: white;
    position: relative;
    cursor: pointer;
    color: white;
    user-select: none;
    overflow: hidden;
    text-align: center;

}
.QuizBoxmiansecond {
    margin-left: 15px;
    margin-bottom: 50px;
    display: flex;
    margin-top: 5px;
    
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 20px;
}
.Questionsection {
    margin-top: 75px;
    padding: 15px;
    color: black;

}
.classMainBox{
    width: 100%;
    /* overflow: scroll;
    display: flex; */
     
}
.QuizBoxmiansecond img {
    height: auto;
    object-fit: contain;
    width: 162px;
    border-radius: 20px;
}
.css-1q1iwm8 {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255 92 123);
    color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 15px;
    width: 200px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.QuizBoxmiansecond h2 {
    color: black;
    font-weight: 500;
    font-size: 18px;
    padding: 10px;
    text-align: center;
    margin: 0px;
}
.New {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    
}
.New img {
    height: 300px;
    object-fit: contain;
    width: 366px;
    border-radius:20px
}
.New p{
    color: #D269E6;
    line-height: 1.33;
    font-size: 26px;
    margin: 40px 0px;
    font-weight: 800;
}
.Questionsection button {
    width: 100%;
    padding: 15px;
    font-size: 20px;

    color: black;
    margin-top: 5px;
    border-radius: 20px;
    border: 2px solid #D269E6;
}

.secondwraper {
    display: flex;
    background: rgb(43, 135, 209);

    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px auto;
    margin-bottom: 46px;
    position: relative;

    align-items: center;
}

.mainColor {
    color: rgb(43, 135, 209)
}

a {
    color: rgb(43, 135, 209) !important;
    text-decoration: none;
}